import Index from './components/Index.vue';
import Category from './components/Category.vue';
import Update from './components/Update.vue';
import Rookie from './components/Rookie.vue';
import Daily from './components/Daily.vue';
import Difference from './components/Difference.vue';
import Score from './components/Score.vue';

export const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index,
    }, {
        name: 'Category',
        path: '/category',
        component: Category,
    }, {
        name: 'Update',
        path: '/update',
        component: Update,
    }, {
        name: 'Rookie',
        path: '/rookie',
        component: Rookie,
    }, {
        name: 'Daily',
        path: '/daily',
        component: Daily,
    }, {
        name: 'Difference',
        path: '/difference',
        component: Difference,
    }, {
        name: 'Score',
        path: '/score',
        component: Score,
    },
];